import React, { useState, useEffect } from "react";
import Container from "../../components/Container";
import Row from "../../components/Row";
import Select from "react-select"; // Importáljuk a loadAllSites függvényt
import { Subscription } from "rxjs";
import { loadAllSites } from "../../lib/firebase/company";
import { functions } from "../../firebase";
import { toast } from "react-toastify";

interface ErrorData {
  id: string;
  value: string;
}
const SolveCheckListScreen = () => {
  const [sites, setSites] = useState<any>([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setSerrors] = useState<ErrorData[]>([]);

  useEffect(() => {
    // Firestore adatlekérés rxfire segítségével
    const subscription = loadAllSites().onSnapshot(
      (snapshot) => {
        const sitesList = snapshot.docs.map((doc) => ({
          value: doc.id,
          label: `${doc.data().companyName} (${doc.id})`, // Feltételezve, hogy a site neve a "name" mezőben van
        }));
        setSites(sitesList);
      },
      (error) => {}
    );

    // Cleanup: az adatfolyam leiratkozása, amikor a komponens unmount-olódik
    return () => subscription();
  }, []);

  // Kiválasztott site-ok kezelése
  const handleSelectChange = (selectedOptions: any) => {
    setSelectedSites(selectedOptions);
  };

  // Submit gomb kezelése
  const handleSubmit = async () => {
    setIsLoading(true);
    for (let x = 0; x < selectedSites.length; x++) {
      const siteId = (selectedSites[x] as any).value;

      try {
        await functions.httpsCallable("createChecklists", { timeout: 540000 })({ siteId });
      } catch (error) {
        setSerrors([
          ...errors,
          {
            id: siteId,
            value: error as string,
          },
        ]);
      }
    }
    if (errors.length <= 0) {
      toast.success("All createChecklists requests has been successfully processed.");
    } else {
      toast.error("Some createChecklists requests failed.");
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <Row>
        <div className="col-lg-12">
          <div className="select-group mg-bottom-20">
            <div
              className="hubl-select"
              style={{
                width: 300,
                paddingLeft: 0,
              }}
            >
              <Select isMulti options={sites} isSearchable onChange={handleSelectChange} placeholder="Select sites" />
            </div>
          </div>
          <button className="btn btn-primary" onClick={isLoading ? undefined : handleSubmit}>
            {isLoading ? "Processing..." : "Submit"}
          </button>
        </div>
        {errors.length > 0 && <span className="mt-5">Errors:</span>}
        <div className="col-lg-12 mt-3">
          {errors.map((error) => (
            <div className="">
              <span>SiteId: {error.id} </span>
              <span>Error:{error.value}</span>
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default SolveCheckListScreen;
