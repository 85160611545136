import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Roles } from "../lib/login/loginSlice";
import { RootState } from "../reducers/rootReducer";
import { getUser } from "../utils/storage";
import { filterAccessChecklistUpload } from "../utils/filter-acces-checklist-upload";

const useHasAccessToEditChecklists = () => {
  const { role } = useSelector((state: RootState) => state.login);
  const { company: companySettings } = useSelector((state: RootState) => state.company);

  console.log("role: ", role);
  return useMemo(() => role === Roles.CompanyManager, [role]);
};

export default useHasAccessToEditChecklists;
