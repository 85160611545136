import { db } from "../../firebase";

export const loadCompanies = () => db.collection("companies");

export const loadCompanyByEmail = (email: string) => db.collection("companies").where("companyManagerEmail", "==", email);

export const loadRegionsByCompany = (companyReference: string) => {
  console.log('loaded company reference', companyReference);
  return  db.collection("companies").doc(companyReference).collection("regions");
};

export const loadAllSites = () => db.collection("sites")

export const loadSiteByReference = (siteReference: string) => db.collection("sites").doc(siteReference);

export const loadChecksBySiteReference = (siteReference: string) => db.collection("sites").doc(siteReference).collection('checklists');

export const loadChecksBySiteAndChecklistReference = (siteReference: string, checklistReference:string) => db.collection("sites").doc(siteReference).collection('checklists').doc(checklistReference);

export const deleteChecklistBySiteAndChecklistReference = (siteReference: string, checklistReference:string) => db.collection("sites").doc(siteReference).collection('checklists').doc(checklistReference).delete();

export const loadRegionByEmail = (email: string) => db.collectionGroup("regions").where("regionManagerEmail", "==", email);

export const loadCompanyByReference = (companyReference: string) => db.collection("companies").doc(companyReference);

export const loadSiteByEmail = (email: string) => { 
  return db.collection("sites").where("siteManager.email", "==", email);
}


export const checkUserIsTrial = (email: string) => { 
  return db.collection("trial_users").where("email", "==", email);
}

export const loadRegionBySiteReference = (siteReference: string) =>
  db.collectionGroup("regions").where("siteReferences", "array-contains", siteReference);
