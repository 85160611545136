import { useMemo } from "react";
import { getUser } from "../utils/storage";

const allowedUsers = [ "itsupport@brashsolutions.co.uk", "rob@completefoodsafety.co.uk"];
const useHasAccessToGenerateChecklist = () => {
  const user = getUser();
  return useMemo(() => allowedUsers.find((d) => d === user?.email) != undefined, [user]);
};

export default useHasAccessToGenerateChecklist;
