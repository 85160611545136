import React, { useEffect } from "react";
import HomeScreen from "./screens/home/HomeScreen";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./components/Layout";
import store from "./store";
import "./assets/styelsheets/application.scss";
import LoginScreen from "./screens/login/LoginScreen";
import CheckListScreen from "./screens/checklist/CheckListScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import TestScreen from "./screens/TestScreen";
import { loadCompany, loadSiteForSiteManagerEmail } from "./lib/company/companySlice";
import { loadAvailableMigrations } from "./lib/migrations/migrationsSlice";
import { LoadingState } from "./lib/company/companyTypes";
import ProductUploadScreen from "./screens/product-upload/ProductUploadScreen";
import SelectCompanyScreen from "./screens/login/SelectCompanyScreen";
import { getUser } from "./utils/storage";
import CheckListEditorScreen from "./screens/checklist/CheckListEditorScreen";
import NotificationsScreen from "./screens/notifications/NotificationsScreen";
import SolveCheckListScreen from "./screens/checklist/SolveChecklistScreen";

export default function App() {
  const fetched = store.getState().company.sitesLoadingState === LoadingState.FETCHED;

  useEffect(() => {
    console.log("start fetching", fetched);
    if (!fetched) {
      const user = getUser();
      if (user) {
        console.log("start fetching user: ", user);
        if (user.superAdmin && user.companyEmail) {
          console.log("start super", user);
          store.dispatch(loadCompany(user.companyEmail));
        } else {
          console.log("start fetching3", user);
          //store.dispatch(loadCompany(user.companyEmail ?? user.email));
          store.dispatch(loadSiteForSiteManagerEmail(user.email));
        }
      }
    }

    store.dispatch(loadAvailableMigrations());
  }, [fetched]);

  return (
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path="/login">
            <LoginScreen />
          </Route>
          <Route path="/select-company">
            <SelectCompanyScreen />
          </Route>
          <Route path="/checklist/:issue">
            <Layout>
              <CheckListScreen />
            </Layout>
          </Route>
          <Route exact path={"/checklist-editor"}>
            <Layout>
              <CheckListEditorScreen />
            </Layout>
          </Route>
          <Route exact path={"/checklist-solve"}>
            <Layout>
              <SolveCheckListScreen />
            </Layout>
          </Route>
          <Route path={"/checklist-editor/:site/:checklist"} exact>
            <Layout>
              <CheckListEditorScreen />
            </Layout>
          </Route>
          <Route path={"/notification-settings"} exact>
            <Layout>
              <NotificationsScreen />
            </Layout>
          </Route>
          <Route path="/test">
            <Layout>
              <TestScreen />
            </Layout>
          </Route>
          <Route path="/product-upload" exact>
            <Layout>
              <ProductUploadScreen />
            </Layout>
          </Route>
          <Route path="/">
            <Layout>
              <HomeScreen />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}
