import React, { useCallback, useEffect } from "react";
import Box from "../../components/Box";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { doLogin } from "../../lib/login/loginSlice";
import { EMAIL_REGEX } from "../../common/patterns";
import { getUser } from "../../utils/storage";

const ErrorFor = ({ errors, name }: { errors: any; name: string }) => {
  return <p className="text-danger mg-top-10">{errors[name] && errors[name].message}</p>;
};

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (values: any) => {
      dispatch(doLogin(values));
    },
    [dispatch]
  );

  useEffect(() => {
    const user = getUser();
    if (user) {
      console.log("user: ", user);
      return; 
    }
  }, []);

  return (
    <div id="login" style={{ height }}>
      <div className="login-box">
        <Box header="Login">
          <form onSubmit={handleSubmit(onSubmit)} className="form" method="post">
            <div>
              <label>E-mail:</label>
              <br />
              <input
                className="form-control"
                {...register("email", {
                  pattern: EMAIL_REGEX,
                  required: "Invalid email address",
                })}
              />
              <ErrorFor errors={errors} name="email" />
            </div>

            <div>
              <label>Password:</label>
              <input
                type="password"
                {...register("password", {
                  required: "Invalid email address",
                })}
                name="password"
                className="form-control"
              />
              <ErrorFor errors={errors} name="password" />
            </div>
            <div>
              <input type="submit" className="btn-primary btn-xl login-button" value="Login" />
            </div>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default LoginScreen;
